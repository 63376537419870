import { extendTheme } from '@chakra-ui/react';
import '@fontsource/inter';
import { theme as baseTheme } from '@saas-ui/theme-glass';

const theme = extendTheme({
	fonts: {
		body: `'Inter', sans-serif`
	},
	baseTheme
});

export default theme;
