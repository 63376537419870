import { Container, Spinner } from '@chakra-ui/react';

export default function Loading() {
	return (
		<Container
			maxW="100%"
			minH="100%"
			justifyContent="center"
			alignItems="center"
			display="flex"
		>
			<Spinner size="xl" />
		</Container>
	);
}
