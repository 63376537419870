import { App, AppData, Chat } from './types/dynamic-app';
import axios from 'axios';
import { FC, ReactNode, createContext, useState } from 'react';
import { useToast } from '@chakra-ui/react';
import { Model } from './types/model';
import { useAuth } from './providers/MaybeAuthProvider'; // Adjust the path as needed
import { EventSource } from 'eventsource';

interface State {
	status: AppData;
	apps: App[];
	currentChat?: Chat;
	chats: Chat[];
	models: Model[];
	files: File[];
}

interface StoreContextProps {
	state: State;
	setStatus: (status: AppData) => void;
	callAction: (action: string, streaming: boolean, status: AppData) => Promise<AppData | null>;
	loading: boolean;
	fetchingChats: boolean;
	authenticating: boolean;
	actionLoading: Set<string>;
	getActionLoading: (action?: string) => boolean;
	toggleActionLoading: (action?: string) => void;
	chatSaving: boolean;
	setState: (state: State) => void;
	addFile: (file: File) => void;
	removeFile: (idx: number) => void;
	talkToChat: (message: string, id?: number) => void;
	fetchApp: (name?: string) => void;
	fetchChat: (id: number) => void;
	fetchApps: () => void;
	fetchChats: () => Promise<void>;
	fetchModels: () => Promise<void>;
	resetState: () => void;
	resetChat: () => void;
	saveChat: () => void;
	setModel: (id: number) => void;
	deleteChat: (id: number) => void;
	currentApp: string;
}

export const StoreContext = createContext<StoreContextProps>({} as StoreContextProps);

const initialChat = {
	context_length: 1000,
	temperature: 0.7,
	top_p: 0.8,
	model: 1,
	messages: [
		{
			role: 'system',
			content: "You're a helpful chat assistant"
		}
	]
};

const StoreProvider: FC<{ children: ReactNode }> = ({ children }) => {
	const { isLoading: authenticating, user } = useAuth();
	const [state, setState] = useState<State>({
		status: {} as AppData,
		apps: [],
		chats: [],
		models: [],
		files: [],
		currentChat: { ...initialChat } as Chat
	});
	const [fetchingChats, setFetchingChats] = useState(true);
	const [loading, setLoading] = useState(true);
	const [actionLoading, setActionLoading] = useState(new Set<string>());
	const [chatSaving, setChatSaving] = useState(false);
	const [currentApp, setCurrentApp] = useState('');

	const toast = useToast();

	const getActionLoading = (action?: string) => {
		if (!action) return false;
		return actionLoading.has(action);
	};

	const toggleActionLoading = (action?: string) => {
		if (!action) return;
		const newActionLoading = new Set(actionLoading);
		if (newActionLoading.has(action)) {
			setActionLoading((prev) => new Set([...Array.from(prev)].filter((x) => x !== action)));
		} else {
			setActionLoading((prev) => new Set(prev.add(action)));
		}
	};

	const addFile = (file: File) => {
		setState({
			...state,
			files: [...state.files, file]
		});
	};

	const removeFile = (idx: number) => {
		setState({
			...state,
			files: state.files.splice(idx)
		});
	};

	const resetState = () => {
		setLoading(true);
		setCurrentApp('');
	};

	const resetChat = () => {
		state.status.page.content.right_upper_panel.message_list = [];
		setState({ ...state, currentChat: initialChat as Chat });
	};

	const talkToChat = async (message: string, id?: number) => {
		if (!state.currentChat) return;
		toggleActionLoading(state.status.page.content.right_lower_panel.send_button.action);

		state.status.page.content.right_upper_panel.message_list.push({
			role: 'user',
			content: message
		});
		state.status.page.content.right_upper_panel.message_list.push({ role: 'assistant' });

		const formData = new FormData();
		formData.append('message', message);
		formData.append('model', state.currentChat.model.toString());
		formData.append('temperature', state.currentChat.temperature.toString());
		formData.append('top_p', state.currentChat.top_p.toString());
		formData.append('context_length', state.currentChat.context_length.toString());
		if (state.currentChat.messages)
			formData.append(
				'prompt',
				state.currentChat.messages.filter((el) => el.role === 'system')[0].content as string
			);
		if (state.files.length > 0)
			state.files.forEach((file, idx) => formData.append('files', file));

		const { data: chatData } = await axios.postForm<Chat>(
			process.env.REACT_APP_API_URL + '/chats' + (id ? `/${id}` : ''),
			formData,
			{ withCredentials: true }
		);
		state.status.page.content.right_upper_panel.message_list = chatData.messages
			? chatData.messages.filter((el) => el.role !== 'system')
			: [];
		state.currentChat = chatData;
		if (!id) state.chats.push(chatData);
		setState(state);

		const el = document.getElementsByClassName('messages__box')[0];
		if (el) {
			el.scrollTop = el.scrollHeight;
		}

		toggleActionLoading(state.status.page.content.right_lower_panel.send_button.action);
	};

	const deleteChat = async (id: number) => {
		setFetchingChats(true);

		await axios.delete<Chat>(process.env.REACT_APP_API_URL + '/chats/' + id, {
			withCredentials: true
		});
		if (state.currentChat?.id === id) {
			resetChat();
		}

		await fetchChats();

		setFetchingChats(false);
	};

	const saveChat = async () => {
		setChatSaving(true);

		await axios.put<Chat>(
			process.env.REACT_APP_API_URL + '/chats/' + state.currentChat?.id,
			{
				context_length: state.currentChat?.context_length,
				model: state.currentChat?.model,
				temperature: state.currentChat?.temperature,
				top_p: state.currentChat?.top_p,
				prompt: state.currentChat?.messages?.filter((el) => el.role === 'system')[0].content
			},
			{ withCredentials: true }
		);

		setChatSaving(false);
	};

	const fetchChat = async (id: number) => {
		setFetchingChats(true);

		const { data: chatData } = await axios.get<Chat>(
			process.env.REACT_APP_API_URL + '/chats/' + id,
			{ withCredentials: true }
		);
		state.status.page.content.right_upper_panel.message_list = chatData.messages
			? chatData.messages.filter((el) => el.role !== 'system')
			: [];
		state.currentChat = chatData;
		setState({ ...state, currentChat: chatData });

		setFetchingChats(false);
	};

	const fetchApp = async (name?: string) => {
		setLoading(true);

		const token = user?.access_token;
		const { data: pageData } = await axios.get(
			process.env.REACT_APP_API_URL + '/get_page' + (name ? `/${name}` : ''),
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		state.status = pageData.status;
		setState(state);
		setCurrentApp(name || '');

		setLoading(false);
	};

	const fetchChats = async () => {
		setFetchingChats(true);

		const token = user?.access_token;
		const { data: chatsData } = await axios.get(process.env.REACT_APP_API_URL + '/chats', {
			headers: { Authorization: `Bearer ${token}` }
		});
		state.chats = chatsData;
		setState(state);

		setFetchingChats(false);
	};

	const fetchModels = async () => {
		setLoading(true);

		const token = user?.access_token;
		const { data: models } = await axios.get<Model[]>(
			process.env.REACT_APP_API_URL + '/models',
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		state.models = models;
		setState(state);

		setLoading(false);
	};

	const fetchApps = async () => {
		setLoading(true);

		const token = user?.access_token;
		const { data: appsData } = await axios.get(process.env.REACT_APP_API_URL + '/get_apps', {
			headers: { Authorization: `Bearer ${token}` }
		});
		state.apps = appsData.apps;
		setState(state);

		setLoading(false);
	};

	const setStatus = (status: AppData) => {
		setState({ ...state, status });
	};

	const setModel = (id: number) => {
		if (state.currentChat) {
			state.currentChat.model = id;
			setState({ ...state, currentChat: state.currentChat });
		}
	};

	const callAction = async (action: string, streaming: boolean, status: AppData) => {
		console.log('CALLING ACTION');
		console.log(action, streaming, status);
		toggleActionLoading(action);
		console.log('TOGGLING ON', action, JSON.stringify(actionLoading));
		if (!streaming) {
			const token = user?.access_token;
			const { data, headers } = await axios.post<{
				status?: AppData;
				partial_status?: { target: string; data: any };
				message?: string;
				error?: string;
			}>(
				process.env.REACT_APP_API_URL + '/on_action/' + currentApp,
				{ status: { ...status, action } },
				{ headers: { Authorization: `Bearer ${token}` } }
			);

			if (data.message) {
				toast({
					title: 'Success',
					description: data.message,
					status: 'success',
					position: 'top-right',
					duration: 4500
				});
			} else if (data.error) {
				toast({
					title: 'Error',
					description: data.error,
					status: 'error',
					position: 'top-right',
					duration: 4500
				});
			} else if (data.partial_status) {
				try {
					const partialStatus = data.partial_status;
					const splitPartialStatus = partialStatus.target.split('.');
					let newStatus = { ...status }; // Create a shallow copy of the status object
					let currentLevel = newStatus as any;

					for (let i = 0; i < splitPartialStatus.length; i++) {
						const key = splitPartialStatus[i];

						if (i === splitPartialStatus.length - 1) {
							currentLevel[key] = partialStatus.data; // Set the data at the last level
						} else {
							// Check if the key exists at the current level
							if (!currentLevel[key]) {
								throw new Error(`Target ${partialStatus.target} doesn't exist`);
							}
							currentLevel = currentLevel[key]; // Move deeper into the object
						}
					}

					setStatus(newStatus); // Update the status with the new value
				} catch (error) {
					toast({
						title: 'Error',
						description: `Target ${data.partial_status.target} doesn't exist`,
						status: 'error',
						position: 'top-right',
						duration: 4500
					});
				}
			} else if (data.status) {
				console.log('set_status');
				setStatus(data.status);

				const el = document.getElementsByClassName('messages__box')[0];
				if (el) {
					el.scrollTop = el.scrollHeight;
				}
			} else {
				if (
					process.env.NODE_ENV === 'development' &&
					headers['content-type'] !== 'text/event-stream'
				) {
					toast({
						title: 'Error',
						description: 'Streaming for this action is not supported on the backend',
						status: 'error',
						position: 'top-right',
						duration: 4500
					});
					toggleActionLoading(action);
					return status;
				}
			}
			toggleActionLoading(action);
			console.log('TOGGLING OFF', action, JSON.stringify(actionLoading));
			return status;
		}

		let newStatus = status;
		try {
			const es = new EventSource(process.env.REACT_APP_API_URL + '/on_action/' + currentApp, {
				withCredentials: !!process.env.REACT_APP_AUTH,
				fetch: (input) =>
					fetch(input, {
						method: 'POST',
						mode: 'cors',
						cache: 'no-cache',
						headers: {
							'Content-Type': 'application/json',
							Authorization: `Bearer ${user?.access_token}`
						},
						body: JSON.stringify({ status: { ...status, action } })
					})
			});

			es.addEventListener('init_status', ({ data }) => {
				newStatus = JSON.parse(data).status;
				setStatus(newStatus);
			});

			es.addEventListener('status', ({ data }) => {
				newStatus = JSON.parse(data).status;
				setStatus(newStatus);
			});

			es.addEventListener('data', ({ data }) => {
				const messageList = newStatus.page.content.right_upper_panel.message_list;

				messageList[messageList.length - 1].content += data;
				setStatus(newStatus);

				const el = document.getElementsByClassName('messages__box')[0];
				if (el) {
					el.scrollTop = el.scrollHeight;
				}
			});

			es.addEventListener('message_log', ({ data }) => {
				const message = JSON.parse(data);
				const messageList = newStatus.page.content.right_upper_panel.message_list;
				if (messageList.length > 0) {
					const logs = messageList[messageList.length - 1].logs;
					if (!logs) messageList[messageList.length - 1].logs = [message];
					else logs.push(message);
					setStatus(newStatus);
				}
			});

			es.addEventListener('status_partial', ({ data }) => {
				const partialStatus = JSON.parse(data).partial_status;
				const splitPartialStatus = partialStatus.target.split('.');
				let newStatus = status as any;
				for (let i = 0; i < splitPartialStatus.length; i++) {
					const key = splitPartialStatus[i];
					if (i === splitPartialStatus.length - 1) {
						newStatus[key] = partialStatus.data;
					} else {
						newStatus = newStatus[key];
					}
				}
				setStatus(newStatus);
			});

			es.addEventListener('update_multiprocesses', ({ data }) => {
				const {
					process_id,
					data: { status }
				} = JSON.parse(data);
				const processes = newStatus.page.content.left_panel.multiprocessing.processes;
				if (processes) {
					const process = processes.find((el) => el.process_id === process_id);
					if (process) {
						process.status = status;
						setStatus(newStatus);
					}
				}
			});

			es.onerror = (error) => {
				toast({
					title: 'Error',
					description: 'Streaming for this action is not supported on the backend',
					status: 'error',
					position: 'top-right',
					duration: 4500
				});
				toggleActionLoading(action);
			};

			setTimeout(() => {
				es.close();
			}, 10_000);
		} catch (error) {
			console.log(error);
		}

		return null;
	};

	return (
		<StoreContext.Provider
			value={{
				state,
				setStatus,
				callAction,
				loading,
				actionLoading,
				getActionLoading,
				toggleActionLoading,
				fetchApp,
				fetchApps,
				fetchModels,
				resetState,
				fetchChats,
				fetchChat,
				saveChat,
				setState,
				removeFile,
				addFile,
				authenticating,
				chatSaving,
				fetchingChats,
				deleteChat,
				resetChat,
				talkToChat,
				setModel,
				currentApp
			}}
		>
			{children}
		</StoreContext.Provider>
	);
};

export default StoreProvider;
