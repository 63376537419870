import {
	Box,
	Button,
	Card,
	HStack,
	Skeleton,
	StackDivider,
	Text,
	VStack,
	useColorModeValue
} from '@chakra-ui/react';
import { Message } from '../types/dynamic-app';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactMarkdown from 'react-markdown';
import ChakraUIRenderer from 'chakra-ui-markdown-renderer';
import { useContext, useRef, useState } from 'react';
import DynamicContent from './sidebar/DynamicContent';
import AutoResizeTextarea from './AutoResizeTextarea';
import { StoreContext } from '../store';
import Logs from './Logs';

interface Props {
	id: number;
	message: Message;
}

export default function MessageComponent({ message: el, id: key }: Props) {
	const textRef = useRef<HTMLTextAreaElement>(null);
	const [editing, setEditing] = useState(false);
	const color = useColorModeValue('gray.300', 'gray.700');
	const assistantColor = useColorModeValue('gray.100', 'gray.700');
	const userColor = useColorModeValue('gray.200', 'gray.900');
	const {
		state: { status },
		callAction,
		actionLoading
	} = useContext(StoreContext);
	const [copied, setCopied] = useState(false);

	const copy = (content: string) => {
		navigator.clipboard.writeText(content);
		setCopied(true);
		setTimeout(() => setCopied(false), 2000);
	};

	return (
		<HStack alignItems="start" spacing={8} position="relative" minW="100%" role="group">
			{el.role === 'assistant' && (
				<Box p={2}>
					<FontAwesomeIcon icon="envelope" size="lg" />
				</Box>
			)}
			{el.content && el.content.length > 0 && !editing && (
				<VStack divider={<StackDivider borderColor={color} />} width="100%">
					<Card
						w="100%"
						borderRadius={16}
						padding={4}
						backgroundColor={el.role === 'assistant' ? assistantColor : userColor}
					>
						{el.content ? (
							<Text textAlign="start" width="100%">
								<ReactMarkdown
									components={ChakraUIRenderer()}
									children={el.content}
									skipHtml
								/>
							</Text>
						) : (
							<Skeleton height={6} width="100%" />
						)}

						<HStack
							position="absolute"
							display="none"
							right={2}
							bottom={2}
							_groupHover={{ display: 'inline' }}
						>
							{el.copiable && el.content && (
								<Button
									variant="ghost"
									size="sm"
									transition="all 200ms linear"
									onClick={() => copy(el.content as string)}
									color={copied ? 'green.300' : ''}
								>
									<FontAwesomeIcon
										icon={copied ? 'check-circle' : 'copy'}
										width={16}
									/>
								</Button>
							)}
							{el.modifiable && (
								<Button
									variant="ghost"
									size="sm"
									transition="all 200ms linear"
									onClick={() => setEditing(!editing)}
								>
									<FontAwesomeIcon
										icon={editing ? 'check' : 'pen-to-square'}
										width={16}
									/>
								</Button>
							)}
							{el.rateable && (
								<>
									<Button
										variant="ghost"
										size="sm"
										transition="all 200ms linear"
										disabled={actionLoading.has('rate')}
										onClick={() => {
											if (!status.page.variables) status.page.variables = {};
											status.page.variables['rated_key'] = key;
											status.page.variables['rated_value'] = true;
											callAction('rate', false, status);
										}}
										color="green.300"
									>
										<FontAwesomeIcon icon={'thumbs-up'} width={16} />
									</Button>
									<Button
										variant="ghost"
										size="sm"
										transition="all 200ms linear"
										disabled={actionLoading.has('rate')}
										onClick={() => {
											if (!status.page.variables) status.page.variables = {};
											status.page.variables['rated_key'] = key;
											status.page.variables['rated_value'] = false;
											callAction('rate', false, status);
										}}
										color="red.300"
									>
										<FontAwesomeIcon icon={'thumbs-down'} width={16} />
									</Button>
								</>
							)}
						</HStack>
					</Card>

					{el.form && <DynamicContent columns={1} form={el.form} />}
					{el.logs && <Logs logs={el.logs} />}
				</VStack>
			)}
			{el.role === 'user' && <FontAwesomeIcon icon="user" size="lg" />}
			{el.content && el.content.length > 0 && editing && (
				<AutoResizeTextarea
					ref={textRef}
					maxW={'container.lg'}
					width={'container.lg'}
					textAlign="start"
					defaultValue={el.content}
					onChange={(e: any) => (el.content = e.target.value)}
				/>
			)}
		</HStack>
	);
}
