import { Card, CloseButton, HStack, Image, Text, VStack } from '@chakra-ui/react';

interface Props {
	idx: number;
	file: File;
	onClose: () => void;
}

export default function FileCard({ idx, file, onClose }: Props) {
	const extension = file.name.split('.').pop();

	return (
		<Card p={3} borderRadius={16} variant="outline" userSelect="none" w="max-content">
			<HStack spacing={4}>
				<Image width={8} height={8} src={`/svg/file/${extension}.svg`} />

				<VStack spacing={0}>
					<Text fontSize="small">{file.name}</Text>
					<Text fontSize="smaller" color="gray.400">
						{extension}
					</Text>
				</VStack>

				<CloseButton onClick={onClose} />
			</HStack>
		</Card>
	);
}
