import { StoreContext } from '../store';
import { Select } from '@chakra-ui/react';
import { useTolgee } from '@tolgee/react';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import { useContext, useEffect } from 'react';

export default function LanguageSelector() {
	const LANGUAGES = ['en', 'nl', 'fr'];

	const {
		state: { status },
		setStatus
	} = useContext(StoreContext);
	const { getLanguage, changeLanguage } = useTolgee(['language']);

	const lang = getLanguage();
	useEffect(() => {
		if (!status.page) return;
		if (!status.page.variables) status.page.variables = {};
		if (lang) status.page.variables.language = lang;
		setStatus(status);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lang, status.page]);

	return (
		<Select value={getLanguage()} onChange={(e) => changeLanguage(e.target.value)}>
			{LANGUAGES.map((el) => (
				<option value={el} key={el}>
					{getUnicodeFlagIcon(el)}
				</option>
			))}
		</Select>
	);
}
