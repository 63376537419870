import { StoreContext } from '../../store';
import { Card, CardBody, Checkbox, SimpleGrid, Text, VStack } from '@chakra-ui/react';
import { useContext } from 'react';
import { startCase, toLower } from 'lodash';
import { SelectableResult } from '@/types/dynamic-app';

export default function ClientInfo() {
	const {
		state: { status }
	} = useContext(StoreContext);
	const { page } = status;

	return (
		<Card
			w="100%"
			variant="outline"
			maxH="512px"
			overflowY="auto"
			css={{
				'&::-webkit-scrollbar': {
					width: '4px'
				},
				'&::-webkit-scrollbar-track': {
					width: '6px'
				},
				'&::-webkit-scrollbar-thumb': {
					background: '#e2e8f066',
					borderRadius: '24px'
				}
			}}
		>
			<CardBody padding={2}>
				<SimpleGrid columns={2} spacing={2}>
					{page.content.left_panel.search_results.selected_search &&
						Object.keys(page.content.left_panel.search_results.selected_search).map(
							(key) => (
								<>
									{page.content.left_panel.search_results.selected_search![
										key
									] && (
										<VStack alignItems="start" spacing={0} key={key}>
											<Text fontSize="sm" fontWeight="bold">
												{startCase(toLower(key))}
											</Text>
											{typeof page.content.left_panel.search_results
												.selected_search![key] == 'string' && (
												<Text fontSize="sm">
													{
														page.content.left_panel.search_results
															.selected_search![key] as string
													}
												</Text>
											)}
											{Array.isArray(
												page.content.left_panel.search_results
													.selected_search![key] as SelectableResult[]
											) && (
												<SimpleGrid columns={1} spacing={2}>
													{(
														page.content.left_panel.search_results
															.selected_search![
															key
														] as SelectableResult[]
													).map((el) => (
														<Checkbox
															size="sm"
															onChange={(e) =>
																(page.content.left_panel.search_results.array_selects![
																	key
																][el.id] = e.target.checked)
															}
														>
															{el.id}{' '}
															{el.description &&
																'- ' + el.description}
														</Checkbox>
													))}
												</SimpleGrid>
											)}
										</VStack>
									)}
								</>
							)
						)}
				</SimpleGrid>
			</CardBody>
		</Card>
	);
}
