import { SearchResult, SelectableResult } from '@/types/dynamic-app';
import { StoreContext } from '../../store';
import { Card, CardBody, SimpleGrid, Text, VStack } from '@chakra-ui/react';
import { startCase, toLower } from 'lodash';
import { useContext } from 'react';

interface Props {
	callback: () => void;
}

export default function PopoverSubContent({ callback }: Props) {
	const {
		state: { status },
		setStatus,
		callAction
	} = useContext(StoreContext);
	const { page } = status;

	const select = (el: SearchResult) => {
		const results = page.content.left_panel.search_results;
		if (results.id_key) {
			results.selected_search = el;
		}
		results.results = [];

		const filters = page.content.left_panel.search_bar.filters;
		if (filters) {
			const keys = Object.keys(filters);
			for (let i = 0; i < keys.length; i++) {
				filters[keys[i]] = '';
			}
		}

		console.log('SELECTED');

		setStatus(status);
		callback();

		if (el['action'] && typeof el['action'] === 'string') {
			callAction(el['action'], false, status);
		}

		if (status.page.content.left_panel.search_bar.action) {
			callAction(
				status.page.content.left_panel.search_bar.action,
				!!status.page.content.left_panel.search_bar.streaming,
				status
			);
		}
	};

	return (
		<>
			{page.content.left_panel.search_results.results &&
				page.content.left_panel.search_results.results.length > 0 && (
					<VStack
						userSelect="none"
						maxH="256px"
						overflowY="auto"
						css={{
							'&::-webkit-scrollbar': {
								width: '4px'
							},
							'&::-webkit-scrollbar-track': {
								width: '6px'
							},
							'&::-webkit-scrollbar-thumb': {
								background: '#e2e8f066',
								borderRadius: '24px'
							}
						}}
						w="100%" // Ensure full width
						p={2} // Add padding for better spacing
					>
						{page.content.left_panel.search_results.results.map((el) => (
							<Card
								w="100%"
								variant="outline"
								cursor="pointer"
								onClick={() => select(el)}
								_hover={{ bg: 'gray.50' }} // Add hover effect for better UX
							>
								<CardBody padding={3}>
									{' '}
									{/* Increased padding */}
									<SimpleGrid columns={4} spacing={3}>
										{' '}
										{/* Increased spacing */}
										{Object.keys(el).map((key) => (
											<VStack alignItems="start" spacing={1} key={key}>
												<Text fontSize="sm" fontWeight="bold">
													{startCase(toLower(key))}
												</Text>
												{typeof el[key] === 'string' && (
													<Text fontSize="sm">{el[key].toString()}</Text>
												)}
												{Array.isArray(el[key]) && (
													<Text fontSize="sm">
														{(el[key] as SelectableResult[])
															.map((el) => el.id)
															.join(', ')}
													</Text>
												)}
											</VStack>
										))}
									</SimpleGrid>
								</CardBody>
							</Card>
						))}
					</VStack>
				)}
		</>
	);
}
