// src/pages/AuthCallback.tsx

import { useEffect, useState } from 'react';
import { useAuth } from '../providers/MaybeAuthProvider'; // Updated import
import { useNavigate } from 'react-router-dom';
import { Container, Spinner, Text, Button } from '@chakra-ui/react';

export default function AuthCallback() {
	const auth = useAuth();
	const navigate = useNavigate();
	const [error, setError] = useState(false);
	const authEnabled = process.env.REACT_APP_AUTH === '1';

	useEffect(() => {
		if (!authEnabled) {
			// If auth is disabled, redirect to the home page
			navigate('/');
			return;
		}

		if (!auth.isLoading) {
			if (auth.isAuthenticated) {
				navigate('/');
			} else {
				// Limit redirect attempts to prevent infinite loops
				const redirectAttempts = Number(sessionStorage.getItem('redirectAttempts') || 0);
				if (redirectAttempts < 2) {
					sessionStorage.setItem('redirectAttempts', String(redirectAttempts + 1));
					auth.signinRedirect();
				} else {
					// After failed attempts, show an error message
					setError(true);
				}
			}
		}
	}, [auth, navigate, authEnabled]);

	if (auth.isLoading) {
		return (
			<Container
				maxW="100%"
				minH="100vh"
				display="flex"
				justifyContent="center"
				alignItems="center"
			>
				<Spinner size="xl" />
			</Container>
		);
	}

	if (error) {
		return (
			<Container
				maxW="100%"
				minH="100vh"
				display="flex"
				flexDirection="column"
				justifyContent="center"
				alignItems="center"
				textAlign="center"
			>
				<Text mb={4}>Une erreur est survenue lors de l'authentification.</Text>
				<Button
					onClick={() => {
						sessionStorage.removeItem('redirectAttempts');
						auth.signinRedirect();
					}}
				>
					Réessayer
				</Button>
			</Container>
		);
	}

	return null;
}
