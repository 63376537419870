import {
	VStack,
	Button,
	Input,
	HStack,
	Text,
	useColorModeValue,
	Menu,
	MenuButton,
	MenuItem,
	MenuList
} from '@chakra-ui/react';
import { StoreContext } from '../../store';
import { useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AddIcon, ChatIcon } from '@chakra-ui/icons';

export default function SidebarContent() {
	const [searchTerm, setSearchTerm] = useState('');
	const {
		state: { chats, currentChat },
		deleteChat,
		fetchChat,
		resetChat
	} = useContext(StoreContext);

	const selectedColor = useColorModeValue('gray.300', 'gray.800');
	const backgroundColor = useColorModeValue('gray.100', 'gray.700');

	return (
		<VStack w="100%" h="100%" justifyContent="space-between" paddingTop={4} paddingBottom={4}>
			<VStack spacing={2} w="100%">
				<HStack>
					<Input
						placeholder="Search chats"
						paddingRight={12}
						onChange={(e) => setSearchTerm(e.target.value)}
					/>
					<Button onClick={resetChat}>
						<AddIcon w={3} h={3} color="gray.300" />
					</Button>
				</HStack>
				{chats.length > 0 && (
					<VStack w="100%">
						{chats
							.filter(
								(el) =>
									el.title &&
									el.title.toLowerCase().includes(searchTerm.toLowerCase())
							)
							.reverse()
							.map((el) => (
								<Button
									variant="ghost"
									transition="background-color 50ms ease-in-out"
									bg={
										currentChat && currentChat.id === el.id
											? selectedColor
											: backgroundColor
									}
									w="100%"
									padding={2.5}
									key={el.id}
									role="group"
									onClick={() => el.id && fetchChat(el.id)}
								>
									<HStack w="100%">
										<ChatIcon />
										<Text
											fontWeight="medium"
											noOfLines={1}
											fontSize="sm"
											userSelect="none"
										>
											{el.title}
										</Text>

										<Menu>
											{({ isOpen, onClose }) => (
												<>
													<MenuButton
														as={Button}
														variant="ghost"
														display={isOpen ? 'inline' : 'none'}
														position="absolute"
														right={0}
														color={isOpen ? 'white' : 'gray'}
														_hover={{ color: 'white' }}
														_groupHover={{ display: 'inline' }}
														onClick={(e) => {
															e.stopPropagation();
														}}
													>
														<FontAwesomeIcon
															icon="ellipsis-vertical"
															size="sm"
														/>
													</MenuButton>
													<MenuList p={0}>
														<MenuItem
															fontWeight="medium"
															px={4}
															py={2}
															isDisabled={true}
															onClick={(e) => {
																e.stopPropagation();
															}}
														>
															<FontAwesomeIcon
																icon="edit"
																size="sm"
															/>
															<Text ml={2}>Rename</Text>
														</MenuItem>
														<MenuItem
															fontWeight="medium"
															px={4}
															py={2}
															onClick={(e) => {
																e.stopPropagation();
																if (el.id) deleteChat(el.id);
															}}
														>
															<FontAwesomeIcon
																icon="trash"
																size="sm"
															/>
															<Text ml={2}>Delete</Text>
														</MenuItem>
													</MenuList>
												</>
											)}
										</Menu>
									</HStack>
								</Button>
							))}
					</VStack>
				)}
			</VStack>
		</VStack>
	);
}
