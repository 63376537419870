import { StoreContext } from '../../store';
import {
	Button,
	Card,
	Flex,
	FormControl,
	FormLabel,
	HStack,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Select,
	SimpleGrid,
	Spinner,
	Text,
	Tooltip,
	useColorModeValue,
	useDisclosure,
	VStack
} from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import AutoResizeTextarea from '../AutoResizeTextarea';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DynamicForm, File } from '@/types/dynamic-app';
import Dropzone from 'react-dropzone';
import { CheckCircleIcon, CloseIcon, DownloadIcon } from '@chakra-ui/icons';
import { useTranslate } from '@tolgee/react';
import AudioRecorder from '../AudioRecorder';

interface Props {
	form: DynamicForm;
	columns: number;
}

export default function DynamicContent({ form, columns }: Props) {
	const SPACING = '8px';
	const HARDCODED_ELEMENTS = [
		'title_left_panel',
		'search_results',
		'search_bar',
		'sidebar_hint',
		'sidebar'
	];

	const { t } = useTranslate();
	const {
		state: { status },
		setStatus,
		callAction
	} = useContext(StoreContext);

	const { register, control, setValue } = useForm();
	const [prevVals, setPrevVals] = useState(new Map<string, string>());
	const matches = useWatch({ control });
	const bg = useColorModeValue('gray.100', 'gray.700');
	const text = useColorModeValue('gray.500', 'gray.200');

	useEffect(() => {
		for (const key of Object.keys(matches)) {
			const value = matches[key];
			const element = form[key];
			if (element.on_match && element.value !== prevVals.get(key)) {
				const re = new RegExp(element.on_match);
				if (re.exec(value)) {
					(async () => {
						if (element.action) {
							const res = await callAction(element.action, false, status);
							if (res) {
								for (const key of Object.keys(res.page.content.left_panel)) {
									const value = res.page.content.left_panel[key].value;
									if (value) {
										setValue(key, value);
									}
								}
							}
						}
					})();
				}
			}
			if (element.value) setPrevVals(prevVals.set(key, element.value));
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [matches]);

	const handleAction = async (action?: string, streaming?: boolean) => {
		if (action) {
			await callAction(action, !!streaming, status);
		}
	};

	const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();
	const [editKey, setEditKey] = useState('');
	const editElement = (key: string) => {
		onEditOpen();
		setEditKey(key);
	};

	const onSelectChange = async (el: string, newValue: string) => {
		const element = form[el];
		element.selected = newValue;
		if (element.on_select) {
			await handleAction(element.on_select);
		}
	};

	return (
		<SimpleGrid columns={columns} spacing={4} minW="100%">
			{Object.keys(form).map(
				(el, idx) =>
					!HARDCODED_ELEMENTS.includes(el) &&
					!form[el].hidden && (
						<FormControl isRequired={form[el].required} key={idx}>
							<HStack justifyContent="space-between">
								{form[el].title && (
									<FormLabel>
										<Tooltip
											label={form[el].tooltip}
											hasArrow={true}
											placement="bottom-start"
											openDelay={150}
											arrowPadding={4}
										>
											{t(form[el].title!)}
										</Tooltip>
									</FormLabel>
								)}

								{form[el].type === 'input_textarea' && (
									<Button size="xs" onClick={() => editElement(el)}>
										Edit
									</Button>
								)}
							</HStack>
							{form[el].type === 'input_text' && (
								<Input
									placeholder={t(form[el].placeholder!)}
									defaultValue={form[el].value}
									{...register(el, {
										required: form[el].required && 'This is required',
										onChange: (e) => (form[el].value = e.target.value)
									})}
								/>
							)}
							{form[el].type === 'input_date' && (
								<Input
									type="date"
									placeholder={t(form[el].placeholder!)}
									defaultValue={form[el].value}
									{...register(el, {
										required: form[el].required && 'This is required',
										onChange: (e) => (form[el].value = e.target.value)
									})}
								/>
							)}
							{form[el].type === 'input_date' && (
								<Input
									type="date"
									placeholder={t(form[el].placeholder!)}
									defaultValue={form[el].value}
									{...register(el, {
										required: form[el].required && 'This is required',
										onChange: (e) => (form[el].value = e.target.value)
									})}
								/>
							)}
							{form[el].type === 'input_textarea' && (
								<AutoResizeTextarea
									placeholder={t(form[el].placeholder!)}
									defaultValue={form[el].value}
									maxRows={form[el].max_rows}
									{...register(el, {
										required: form[el].required && 'This is required',
										onChange: (e) => (form[el].value = e.target.value)
									})}
								/>
							)}
							{form[el].type === 'select' && (
								<Select
									id={el}
									{...register(el, {
										required: form[el].required && 'This is required',
										onChange: (e) => onSelectChange(el, e.target.value)
									})}
								>
									<option selected hidden disabled value="">
										{t(form[el].placeholder!)}
									</option>
									{form[el].options!.map((el, idx) => (
										<option value={el} key={el}>
											{el && t(el)}
										</option>
									))}
								</Select>
							)}
							{form[el].type === 'button_group' &&
								(() => {
									const children = Object.keys(form[el].children || {});
									const isSingleButton = children.length === 1;

									return (
										<Flex
											flexWrap="wrap"
											justifyContent="center"
											marginX={`calc(${SPACING} / 2)`}
										>
											{children.map((cel, idx) => (
												<Button
													key={idx}
													flexBasis={
														isSingleButton
															? '100%'
															: `calc(50% - ${SPACING})`
													}
													marginBottom={SPACING}
													whiteSpace="normal"
													_even={{
														marginX: SPACING
													}}
													onClick={() =>
														handleAction(
															form[el].children![cel].action,
															form[el].children![cel].streaming
														)
													}
													leftIcon={
														<FontAwesomeIcon
															icon={
																form[el].children![cel]
																	.emoticon as IconName
															}
														/>
													}
												>
													{t(form[el].children![cel].inner_text!)}
												</Button>
											))}
										</Flex>
									);
								})()}
							{form[el].type === 'button_download' && (
								<Button
									leftIcon={<DownloadIcon />}
									onClick={() => window.open(form[el].value, '_blank')}
									width="100%"
								>
									{t(form[el].inner_text!)}
								</Button>
							)}
							{form[el].type === 'multiprocessing' && (
								<VStack>
									{form[el].processes &&
										form[el].processes!.map((el) => (
											<>
												{!el.hidden && (
													<Button
														w="100%"
														leftIcon={<FontAwesomeIcon icon="file" />}
														rightIcon={
															el.status === 'running' ? (
																<Spinner
																	size="sm"
																	color="red.400"
																/>
															) : el.status === 'finished' ? (
																<CheckCircleIcon color="green.400" />
															) : el.status === 'failed' ? (
																<FontAwesomeIcon
																	icon="circle-xmark"
																	style={{ color: 'red' }}
																/>
															) : (
																<></>
															)
														}
														isDisabled={el.status !== 'finished'}
														onClick={() =>
															handleAction(el.action, false)
														}
													>
														<Text
															maxW="70%"
															overflow="hidden"
															textOverflow="ellipsis"
														>
															{el.text}
														</Text>
													</Button>
												)}
											</>
										))}
								</VStack>
							)}
							{form[el].type === 'file_input' && (
								<>
									{form[el].files && form[el].files!.length > 0 ? (
										<Card p={4} variant="outline" cursor="pointer" bg={bg}>
											<HStack w="100%">
												<Text
													w="100%"
													textAlign="center"
													fontSize="small"
													textColor={text}
												>
													Selected <b>{form[el].files!.length}</b> file(s)
												</Text>
												<CloseIcon
													h={3}
													onClick={() => {
														form[el].files = [];
														setStatus(status);
													}}
												/>
											</HStack>
										</Card>
									) : (
										<Dropzone
											accept={form[el].accept}
											maxFiles={form[el].max_files}
											onDrop={(files) => {
												new Promise<File[]>((resolve) => {
													form[el].files = [];
													const insertFiles: File[] = [];
													for (let i = 0; i < files.length; i++) {
														const file = files[i];
														const insertFile: File = {
															name: file.name,
															data: ''
														};

														const reader = new FileReader();
														reader.readAsDataURL(file);
														reader.onload = () => {
															if (reader.result) {
																insertFile.data =
																	reader.result.toString();
																insertFiles.push(insertFile);
																if (
																	insertFiles.length ===
																	files.length
																)
																	resolve(insertFiles);
															}
														};
													}
												}).then((files) => {
													console.log('ADDED');
													form[el].files = files;
													setStatus(status);

													handleAction(
														form[el].on_upload,
														form[el].streaming
													);
												});
											}}
										>
											{({ getRootProps, getInputProps }) => (
												<div {...getRootProps()}>
													<input {...getInputProps()} />
													<Card
														borderStyle="dashed"
														p={4}
														variant="outline"
														cursor="pointer"
														bg={bg}
													>
														<Text
															textAlign="center"
															fontSize="small"
															textColor={text}
														>
															{t('input.file.text')} - Max{' '}
															{form[el].max_files}
														</Text>
													</Card>
												</div>
											)}
										</Dropzone>
									)}
								</>
							)}
							{form[el].type === 'audio_recorder' && <AudioRecorder />}
						</FormControl>
					)
			)}

			<Modal isOpen={isEditOpen} onClose={onEditClose} size="xl">
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>{editKey.length > 0 && t(form[editKey].title!)}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<AutoResizeTextarea
							placeholder={editKey.length > 0 && form[editKey].placeholder}
							id={editKey}
							{...register(editKey, {
								required: 'This is required'
							})}
							maxRows={40}
							style={{ overflowY: 'scroll' }}
						/>
					</ModalBody>

					<ModalFooter>
						<Button onClick={onEditClose}>Save</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</SimpleGrid>
	);
}
