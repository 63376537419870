import { StoreContext } from '../store';
import { ArrowUpIcon, DeleteIcon } from '@chakra-ui/icons';
import {
	Container,
	VStack,
	Text,
	Button,
	Select,
	HStack,
	Image,
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverContent,
	PopoverTrigger
} from '@chakra-ui/react';
import { useContext, useEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import AutoResizeTextarea from './AutoResizeTextarea';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslate } from '@tolgee/react';
import FileCard from './FileCard';

export default function Chatbox() {
	const { t } = useTranslate();
	const inputFile = useRef<HTMLInputElement>(null);
	const {
		state: { status, currentChat, files },
		state,
		callAction,
		actionLoading,
		talkToChat,
		setState,
		setStatus
	} = useContext(StoreContext);
	const { page } = status;
	const [text, setText] = useState('');

	useEffect(() => {
		setText(page.content.right_lower_panel.prompt_bar.value || '');
	}, [page.content.right_lower_panel.prompt_bar.value]);

	const clearChat = () => {
		state.status.page.content.right_upper_panel.message_list = [];
		setStatus(state.status);
	};

	const submitText = async () => {
		if (!page.content.right_lower_panel.send_button.action) return;
		page.content.right_lower_panel.prompt_bar.inner_text = text;
		setText('');
		if (currentChat && page.type === 'chat') {
			await talkToChat(page.content.right_lower_panel.prompt_bar.inner_text, currentChat.id);
		} else {
			console.log(!!page.content.right_lower_panel.send_button.streaming);
			await callAction(
				page.content.right_lower_panel.send_button.action,
				!!page.content.right_lower_panel.send_button.streaming,
				status
			);
		}
	};

	const keyDown = (e: KeyboardEvent) => {
		if (e.key === 'Enter') {
			if (!e.getModifierState('Shift')) {
				e.preventDefault();
				if (text.length !== 0) {
					submitText();
				}
			}
		}
	};

	return (
		<Container maxW={'container.xl'} marginX={'auto'}>
			<VStack align="start">
				{!page.content.right_lower_panel.gpt_version_select.hidden && (
					<HStack minW="container.xs">
						<Text fontWeight="semibold" width="160px">
							GPT version:
						</Text>
						<Select
							onChange={(e) =>
								(page.content.right_lower_panel.gpt_version_select.selected =
									e.target.value)
							}
						>
							{page.content.right_lower_panel.gpt_version_select.options!.map(
								(option, i) => (
									<option key={i} value={option}>
										{option}
									</option>
								)
							)}
						</Select>
					</HStack>
				)}

				{page.content.right_upper_panel.message_list.length > 0 && (
					<Button
						leftIcon={<DeleteIcon />}
						alignSelf="end"
						variant="ghost"
						fontWeight="medium"
						colorScheme="red"
						onClick={clearChat}
					>
						Clear chat
					</Button>
				)}

				{files.length > 0 && (
					<HStack w="100%" overflowX="auto">
						{files.map((file, i) => (
							<FileCard
								idx={i}
								file={file}
								onClose={() => {
									// Remove file with index i
									files.splice(i, 1);
									setState(state);
								}}
							/>
						))}
					</HStack>
				)}

				{!page.content.right_lower_panel.prompt_bar.hidden && (
					<Container maxW={'100%'} padding={0} position={'relative'}>
						<AutoResizeTextarea
							pr="9rem"
							minH={'60px'}
							maxRows={10}
							placeholder={
								page.content.right_lower_panel.prompt_bar.placeholder
									? t(page.content.right_lower_panel.prompt_bar.placeholder)
									: 'Enter your message'
							}
							value={text}
							onChange={(e: any) => setText(e.target.value)}
							onKeyDown={keyDown}
							rounded={20}
							css={{
								'&::-webkit-scrollbar': {
									width: '4px'
								},
								'&::-webkit-scrollbar-track': {
									width: '6px'
								},
								'&::-webkit-scrollbar-thumb': {
									background: '#e2e8f066',
									borderRadius: '24px'
								}
							}}
						/>

						{/* File upload */}
						<input
							type="file"
							id="file"
							accept="application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, text/csv, text/plain"
							ref={inputFile}
							style={{ display: 'none' }}
						/>

						{page.variables && page.variables['rag'] && (
							<Popover placement="top-start">
								{({ onClose }) => (
									<>
										<PopoverTrigger>
											<Button
												size="md"
												minW="50px"
												rounded={16}
												style={{
													position: 'absolute',
													right: 68,
													bottom: 10,
													zIndex: 99
												}}
												isLoading={
													page.content.right_lower_panel.send_button
														.action
														? actionLoading.has(
																page.content.right_lower_panel
																	.send_button.action
															)
														: false
												}
												isDisabled={actionLoading.size > 0}
												variant="outline"
												color="gray.300"
											>
												<FontAwesomeIcon icon="upload" />
											</Button>
										</PopoverTrigger>
										<PopoverContent>
											<PopoverArrow />
											<PopoverBody padding={1}>
												<VStack fontWeight="medium" spacing={1}>
													<Button
														w="100%"
														variant="outline"
														bg="gray.800"
														onClick={() => {
															onClose();
															if (inputFile.current)
																inputFile.current.click();
														}}
													>
														<HStack w="100%">
															<FontAwesomeIcon
																width={20}
																height={20}
																icon="file"
															/>
															<Text>Upload files</Text>
														</HStack>
													</Button>
													<Button
														w="100%"
														variant="outline"
														bg="gray.800"
													>
														<HStack w="100%">
															<Image
																width={5}
																height={5}
																src="/favicon.ico"
															/>
															<Text>Import from AI Factory</Text>
														</HStack>
													</Button>
												</VStack>
											</PopoverBody>
										</PopoverContent>
									</>
								)}
							</Popover>
						)}

						{!page.content.right_lower_panel.send_button.hidden && (
							<Button
								size="md"
								minW="50px"
								rounded={16}
								style={{ position: 'absolute', right: 10, bottom: 10, zIndex: 99 }}
								isLoading={
									page.content.right_lower_panel.send_button.action
										? actionLoading.has(
												page.content.right_lower_panel.send_button.action
											)
										: false
								}
								isDisabled={text.length === 0 || actionLoading.size > 0}
								onClick={submitText}
							>
								<ArrowUpIcon />
							</Button>
						)}
					</Container>
				)}

				{!page.content.right_lower_panel.hints.hidden &&
					page.content.right_lower_panel.hints.inner_text && (
						<Text alignSelf="center" fontSize="sm" color="gray.500">
							<ReactMarkdown
								children={t(page.content.right_lower_panel.hints.inner_text)}
							/>
						</Text>
					)}
			</VStack>
		</Container>
	);
}
