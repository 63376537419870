import { StoreContext } from '../store';
import { HamburgerIcon } from '@chakra-ui/icons';
import { Button, Container } from '@chakra-ui/react';
import { useContext } from 'react';

interface Props {
	onOpen?: () => void;
}

export default function Header(props: Props) {
	const {
		state: { status }
	} = useContext(StoreContext);
	const { page } = status;

	return (
		<Container
			maxW="100%"
			position="absolute"
			left={0}
			top={0}
			paddingBlock={4}
			justifyContent="space-between"
			display="flex"
			pointerEvents="none"
		>
			{page &&
			page.content.left_panel['sidebar'] &&
			page.content.left_panel['sidebar'].slider ? (
				<Button variant="outline" onClick={props.onOpen} pointerEvents="auto">
					<HamburgerIcon />
				</Button>
			) : (
				<div></div>
			)}
			{/* 
			<HStack pointerEvents="auto">
				{
					page && page.type === 'chat' && (
						<ModelSwitcher />
					)
				}

				<LanguageSelector />

				<Button variant='outline' onClick={toggleColorMode}>
					{
						colorMode === 'dark' ? (
							<SunIcon />
						) : (
							<MoonIcon />
						)
					}
				</Button>
			</HStack> */}
		</Container>
	);
}
