// src/index.tsx
import ReactDOM from 'react-dom/client';
import './index.css';
import { ChakraProvider } from '@chakra-ui/react';
import StoreProvider from './store';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Apps from './pages/Apps';
import App from './pages/App';
import Loading from './pages/Loading';
import { DevTools, FormatSimple, Tolgee, TolgeeProvider } from '@tolgee/react';
import theme from './theme';
import enTranslation from './i18n/en.json';
import frTranslation from './i18n/fr.json';
import nlTranslation from './i18n/nl.json';
import MaybeAuthProvider from './providers/MaybeAuthProvider'; // Import the MaybeAuthProvider
import AuthCallback from './pages/AuthCallback';
import ProtectedRoute from './components/ProtectedRoute'; // Import the ProtectedRoute

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const router = createBrowserRouter([
	{
		path: '/',
		element: (
			<ProtectedRoute>
				<Apps />
			</ProtectedRoute>
		)
	},
	{
		path: '/loading',
		element: <Loading />
	},
	{
		path: '/auth/login/callback',
		element: <AuthCallback />
	},
	{
		path: '/:appName',
		element: (
			<ProtectedRoute>
				<App />
			</ProtectedRoute>
		)
	}
]);

// Get browser language
const browserLanguage = navigator.language ? navigator.language.split('-')[0] : 'en';

const tolgee = Tolgee()
	.use(DevTools())
	.use(FormatSimple())
	.init({
		language: browserLanguage,
		staticData: {
			en: enTranslation,
			fr: frTranslation,
			nl: nlTranslation
		}
	});

root.render(
	<TolgeeProvider tolgee={tolgee} fallback="Loading...">
		<ChakraProvider theme={theme}>
			<MaybeAuthProvider>
				<StoreProvider>
					<RouterProvider router={router} />
				</StoreProvider>
			</MaybeAuthProvider>
		</ChakraProvider>
	</TolgeeProvider>
);
