// src/components/ProtectedRoute.tsx

import React from 'react';
import { useLocation } from 'react-router-dom';
import Loading from '../pages/Loading';
import { useAuth } from '../providers/MaybeAuthProvider'; // Updated import

interface ProtectedRouteProps {
	children: React.ReactElement;
}

/**
 * A wrapper for protected routes.
 * Renders children if authenticated, shows a loading spinner if auth is loading,
 * otherwise redirects to the login page.
 */

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
	const auth = useAuth();
	const location = useLocation();
	const authEnabled = process.env.REACT_APP_AUTH === '1';

	if (authEnabled) {
		if (auth.isLoading) {
			return <Loading />;
		}

		if (!auth.isAuthenticated) {
			auth.signinRedirect({ state: { from: location.pathname } });
			return null; // Optionally, you can return a loading indicator here
		}

		return children;
	} else {
		// If auth is disabled, render the children directly
		return children;
	}
};

export default ProtectedRoute;
