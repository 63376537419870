import {
	Container,
	HStack,
	VStack,
	useDisclosure,
	Text,
	useColorModeValue,
	Button,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	useColorMode
} from '@chakra-ui/react';
import AppContainer from '../components/Container';
import { useContext, useEffect, useState } from 'react';
import { StoreContext } from '../store';
import Sidebar from '../components/sidebar/Sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import ReactMarkdown from 'react-markdown';
import SidebarContent from '../components/sidebar/SidebarContent';
import ChatSidebarContent from '../components/sidebar/ChatSidebarContent';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from './Loading';
import { useTranslate } from '@tolgee/react';
import { ChevronDownIcon, MoonIcon, SunIcon } from '@chakra-ui/icons';
import LanguageSelector from '../components/LanguageSelector';
import ModelSwitcher from '../components/ModelSwitcher';

function App() {
	const { t } = useTranslate();
	const params = useParams();
	const navigate = useNavigate();
	const {
		state: { status },
		loading,
		authenticating,
		fetchApp,
		fetchChats,
		fetchModels,
		resetState
	} = useContext(StoreContext);
	const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
	const { page } = status;

	const sideColor = useColorModeValue('gray.50', 'gray.700');

	useEffect(() => {
		if (!authenticating || !process.env.REACT_APP_AUTH) fetchApp(params.appName);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authenticating]);

	const [fetchedChats, setFetchedChats] = useState(false);
	useEffect(() => {
		if (!fetchedChats && page && page.type === 'chat') {
			fetchModels();
			fetchChats();
			setFetchedChats(true);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page]);

	const { colorMode, toggleColorMode } = useColorMode();

	if (loading || authenticating) return <Loading />;
	return (
		<Container minW="100%" minH="100%" maxH="100vh" padding={0} margin={0}>
			<Container
				minW="100%"
				minH="100%"
				maxH="100vh"
				padding={0}
				margin={0}
				flexDir="row"
				display="flex"
			>
				{page.content.left_panel['sidebar'] && page.content.left_panel['sidebar'].slider ? (
					<Sidebar isOpen={isOpen} onClose={onClose} />
				) : (
					<VStack
						minH="100vh"
						overflowY="scroll"
						minW={
							(page.content.left_panel['sidebar'] &&
								page.content.left_panel['sidebar'].width) ||
							'384px'
						}
						maxW={
							(page.content.left_panel['sidebar'] &&
								page.content.left_panel['sidebar'].width) ||
							'384px'
						}
						bg={sideColor}
						paddingInline="16px"
						css={{
							'&::-webkit-scrollbar': {
								width: '4px'
							},
							'&::-webkit-scrollbar-track': {
								width: '6px'
							},
							'&::-webkit-scrollbar-thumb': {
								background: '#e2e8f066',
								borderRadius: '24px'
							}
						}}
					>
						<VStack w="100%">
							<HStack mt="4" w="100%" display="flex" justifyContent="space-between">
								<Button
									variant="outline"
									size="md"
									onClick={() => {
										resetState();
										navigate('/');
									}}
								>
									<FontAwesomeIcon icon="home" href="/" />
								</Button>

								<HStack pointerEvents="auto">
									{page && page.type === 'chat' && <ModelSwitcher />}

									<LanguageSelector />

									<Button variant="outline" onClick={toggleColorMode}>
										{colorMode === 'dark' ? <SunIcon /> : <MoonIcon />}
									</Button>
								</HStack>
								{page.type === 'chat' && (
									<Menu>
										<MenuButton
											as={Button}
											size="sm"
											variant="outline"
											w="100%"
											fontWeight="normal"
											rightIcon={<ChevronDownIcon />}
										>
											Personal
										</MenuButton>
										<MenuList>
											<MenuItem>Download</MenuItem>
											<MenuItem>Create a Copy</MenuItem>
											<MenuItem>Mark as Draft</MenuItem>
											<MenuItem>Delete</MenuItem>
											<MenuItem>Attend a Workshop</MenuItem>
										</MenuList>
									</Menu>
								)}
							</HStack>

							<HStack justifyContent="center" mt="4">
								{page.content.left_panel.title_left_panel.emoticon && (
									<FontAwesomeIcon
										icon={
											page.content.left_panel.title_left_panel
												.emoticon as IconName
										}
									/>
								)}

								<Text fontSize="large" fontWeight="semibold">
									{page.content.left_panel.title_left_panel.text &&
										t(page.content.left_panel.title_left_panel.text)}
								</Text>
							</HStack>

							{page.content.left_panel.sidebar_hint &&
								!page.content.left_panel.sidebar_hint.hidden &&
								page.content.left_panel.sidebar_hint.inner_text && (
									<Text alignSelf="center" fontSize="sm" color="gray.500">
										<ReactMarkdown
											children={
												page.content.left_panel.sidebar_hint.inner_text
											}
										/>
									</Text>
								)}
						</VStack>

						{page.type === 'chat' ? <ChatSidebarContent /> : <SidebarContent />}
					</VStack>
				)}

				<Container maxW="100%">
					{/* <Header onOpen={onOpen} /> */}
					<AppContainer />
				</Container>
			</Container>
		</Container>
	);
}

export default App;
