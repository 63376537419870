import { useState, useContext, useEffect, useRef } from 'react';
import { StoreContext } from '../store'; // Adjust the path as needed
import { useAuth } from '../providers/MaybeAuthProvider'; // Adjust the path as needed

export default function useWebSocket(app: string) {
	const { user } = useAuth(); // Extract the user
	const token = user?.access_token; // Get the access token from the user object
	const {
		state: {
			status: { page },
			status
		},
		setStatus
	} = useContext(StoreContext);
	const [connected, setConnected] = useState(false);
	const [socketState, setSocket] = useState<WebSocket>();
	const statusRef = useRef(status);
	const REACT_APP_AUTH = process.env.REACT_APP_AUTH;

	// Update the ref whenever the status changes
	useEffect(() => {
		statusRef.current = status;
	}, [status]);

	const handleSocketMessage = (event: any) => {
		if (!socketState) return;
		try {
			const data = JSON.parse(event.data);
			if (data.type === 'status') {
				setStatus(data.payload);
			} else if (data.type === 'request_status') {
				if (socketState) {
					socketState.send(
						JSON.stringify({ type: 'status', payload: statusRef.current })
					);
				}
			} else if (data.type === 'transcription_data') {
				const messages = page.content.right_upper_panel.message_list;
				if (messages.length > 0) {
					messages[messages.length - 1].content += data.payload;
				}
				setStatus(status);
				setTimeout(() => {
					const endOfMessagesRef = document.getElementById('end_of_message_ref');
					if (endOfMessagesRef) {
						endOfMessagesRef.scrollIntoView({ behavior: 'smooth' });
					}
				}, 250);
			} else if (data.type === 'end_call') {
				socketState.close();
			}
		} catch (e) {
			console.error(e);
			toast({
				title: 'Error',
				description: 'Error processing message from server, check console.',
				status: 'error',
				position: 'top-right',
				duration: 4500
			});
		}
	};

	const connect = () => {
		if (!token && REACT_APP_AUTH !== '1') {
			// Connect without token
			const socketUrl = `${process.env.REACT_APP_WS_URL}/${app}`;
			const socket = new WebSocket(socketUrl);
			setSocket(socket);

			socket.addEventListener('message', handleSocketMessage);

			socket.onopen = () => {
				setConnected(true);
			};

			socket.onclose = () => {
				setConnected(false);
			};

			return socket;
		} else if (token) {
			// Connect with token
			const socketUrl = `${process.env.REACT_APP_WS_URL}/${app}?token=${token}`;
			const socket = new WebSocket(socketUrl);
			setSocket(socket);

			socket.addEventListener('message', handleSocketMessage);

			socket.onopen = () => {
				setConnected(true);
			};

			socket.onclose = () => {
				setConnected(false);
			};

			return socket;
		} else {
			console.error('No token available for WebSocket connection');
			toast({
				title: 'Authentication Error',
				description: 'Unable to establish WebSocket connection. Please log in again.',
				status: 'error',
				position: 'top-right',
				duration: 4500
			});
			return;
		}
	};

	const disconnect = () => {
		if (socketState && socketState.readyState === WebSocket.OPEN) {
			socketState.close();
		}
	};

	useEffect(() => {
		if (socketState) {
			socketState.addEventListener('message', handleSocketMessage);
			return () => {
				socketState.removeEventListener('message', handleSocketMessage);
			};
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [socketState]);

	return { connected, socket: socketState, connect, disconnect };
}

function toast(arg0: {
	title: string;
	description: string;
	status: string;
	position: string;
	duration: number;
}) {
	// Implement your toast function here or import it from your UI library
}
